<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Get an instant personal</h1>
        <h1>loan with EasyCash app</h1>
        <h4>fast, secure, reliable and works 24/7.</h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/banner01.png" alt="">
      </div>
    </div>
    <div id="selector1" class="inner inner2">
      <h1 style="text-align: center;"><span style="color: #644FFA;">How to</span>  get it？</h1>
      <div class="items">
        <div v-for="(item,index) in itemList" :key="index" class="item_list">
          <span>{{ item.title }}</span>
          <img :src="item.img" alt="">
          <h5>{{ item.txt }}</h5>
        </div>
      </div>
      <div class="inner inner5">
        <h3>Product advantages</h3>
        <div class="inner5_item">
          <div v-for="(item,index) in leftItemList" :key="index" class="content">
            <div class="left_item">
              <img :src="item.img" alt="">
              <div class="item_info">
                <h4>{{ item.title }}</h4>
                <p>{{ item.txt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inner inner6">
        <div class="inner6_item">
          <div class="inner6_item_left">
            <div>
              <h1 class="inner6_item_left_title">How To Contact Us</h1>
              <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="inner6_item_left_list ">
                <img :src="item.img">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
          <div class="inner6_item_right"><img src="@/assets/images/kefu.png" alt=""></div>
        </div>
      </div>
    </div>
  </div></template>

<script>
export default {
  data() {
    return {
      itemList: [
        { title: 1, txt: 'Download the app Search “EasyCash” from the Google Play Store and download it.', img: require('@/assets/images/content01.png') },
        { title: 2, txt: 'Register Sign up with your phone number, finish some infomation in 2 minutes', img: require('@/assets/images/content02.png') },
        { title: 3, txt: 'Apply for a loan Click the “Submit” button to apply for a loan.After that, the money will be received to your bank account.', img: require('@/assets/images/content03.png') }
      ],
      leftItemList: [
        { title: 'Online Application', txt: 'Cell phone fill in the information application', img: require('@/assets/images/Product01.png') },
        { title: 'No collateral required', txt: 'No physical collateral required', img: require('@/assets/images/Product02.png') },
        { title: 'Fast lending', txt: '30s approval, loan out within 3 minutes', img: require('@/assets/images/Product03.png') },
        { title: 'Safe and trustworthy', txt: 'Bank-level encryption to ensure information security', img: require('@/assets/images/Product04.png') }
      ],
      inner6ItemLeftList: [
        { title: 'easycashng@outlook.com', img: require('@/assets/images/email.png') },
        { title: '18Kayode Street,, Apapa, Lagos', img: require('@/assets/images/address.png') },
        { title: '9:00 - 18:00', img: require('@/assets/images/time.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=king.credit.ng'
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:50px calc((100% - 1200px) / 2) 0;
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: #5E50E9;
      height: 426px;
      display: flex;
      justify-content: space-between;
      .left_item{
        box-sizing: border-box;
        padding: 25px;
        border-radius: 10px;
        // width: 500px;
        h1{
          font-size: 40px;
          color: white;
          font-weight: 700;
          // margin-bottom: 10px;
          line-height: 60px;
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: white;
          font-weight: normal;
          margin: 30px 0;

        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
            // }
            // p:nth-child(1){
            //   padding-top: 8px;
            // }
            // :nth-child(2){
            //   align-items: center;
            // }
            // :nth-child(3){
            //   align-items:flex-end;
            //   padding-bottom: 8px;
            // }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          cursor: pointer;
          margin-top: 20px;
          color: white;
          border-radius: 5px;
          img{
            width: 105px;
            height: 38px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        img{
          height: 376px;
          margin-right: 30px;
        }
      }
    }
    .inner2{
      // height: 420px;
      background:#F8F9FF;
      text-align: center;
      padding: 50px 0;

      h1{
        margin-bottom: 50px;
      }
      .items{
        color: #3E1FA5;
        width: 1200px;
        display: flex;
        margin: 20px auto 0px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 70px;
        }
        .item_list{
          position: relative;
          background: #F3F0FA;
          padding: 62px 60px 0px;
          width: 32%;
          border-radius: 10px;
          min-height: 270px;
          text-align: center;
          span{
            border-radius: 50%;
            border: 1px solid #3E1FA5;
            position: absolute;
            line-height: 28px;
            font-weight:600;
            // padding:14px 10px;
            background-color: #fff;
            width: 30px;
            height: 30px;
            top: -14px;
            left: 50%;
            transform: translateX(-50%);
          }
          img{
            width: 80px;
            height: 80px;
          }
          h5{
            text-align: left;
            line-height: 20px;
            margin-top: 18px;
            }
        }
    }
    .inner3{
      margin: 0 auto 50px;
      h3{
        font-size: 24px;
      }
      img{
        width: 100%;
      }
      .inner3_item{
        margin: 50px 0;
        display: flex;
        justify-content: space-between;
        .content{
          background: white;
          width: 280px;
          padding: 25px 20px 30px 20px;
          border-radius: 10px;
          box-shadow: 0px 3px 20px 1px rgba(93,93,93,0.1600);
          .top_item{
            display: flex;
            justify-content: space-between;
            p{
              width: 88px;
            }
            img{
              width: 40px;
              height: 30px;
            }
          }
          .line{
            width: 120px;
            height: 2px;
            background: #4485EC;
            margin: 15px 0;
          }
          .bottom_item{
            line-height: 18px;
            color: #666666;
          }
        }
      }
    }
    .inner4{
      position: relative;
      height: 364px;
      background:#F2FAFF ;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .left_item{
        img{
          width: 280px;
          height: 300px;
        }
      }
      .right_item{
        margin-right:50px;
        p{
          margin: 40px 0;
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        img{
          position: absolute;
          width: 190px;
          height:220px;
          right: calc(15%);
          top: 0px;
        }
        .item_tips{
          background:#E0EEFE ;
          padding: 20px 10px;
          border-radius: 10px;
          width: 450px;
          line-height: 20px;
        }
      }
    }
    .inner5{
      padding: 50px 0;
      background: #F5F5F5;
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 50px;
      }
      h2{
        line-height: 30px;
        margin-bottom: 20px;
      }
      .inner5_item{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: auto;
        .content{
          padding: 40px 20px;
          background: #3E1FA5;
          color: #fff;
          width: 23%;
          border-radius: 15px;
          display: flex;
          font-weight: 600;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 5px #999;
          img{
            width: 76px;
          }
          h4{
            margin-bottom: 20px;
          }
        }
      }
    }
    .inner6{
      .inner6_item{
        display: flex;
        align-items: center;
        width: 1200px;
        .inner6_item_left{
          z-index: 2;
          border-radius: 10px;
          background: #2A1572;
          color: #ffffff;
          padding: 40px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          width: 50%;
          h1{
            text-align:left;
            margin-bottom: 26px;
            width: auto;
          }
          .inner6_item_left_list{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img{
              width: 34px;
              height: 34px;
              margin-right: 18px;
            }
          }

        }
          .inner6_item_right{
            width: 55%;
            z-index: 1;
            margin-left: -40px;
            img{
              padding: 2px;
              // border: 2px dashed rgb(206, 206, 206);
              width: 100%;
            }
          }
      }

    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;;
      height: 600px;
      position: relative;
      .left_item{
        margin: 0px 10px 10px;
        // height: 500px;
        padding: 25px 5px;
        h1{
          font-size: 30px;
          line-height: 50px;
        }
        .item_download {
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }

    .inner2{
      // height: 420px;
      background:#F8F9FF;
      text-align: center;
      padding: 50px 0 0;
      h1{
        width: 100%;
        margin-bottom: 50px;
      }
      .items{
        color: #3E1FA5;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-bottom: 0px;
        }
        .item_list{
          flex: 1;
          position: relative;
          background: #F3F0FA;
          padding: 62px 50px 10px;
          border-radius: 10px;
          text-align: center;
          width: 80%;
          margin: 0 auto 30px;
          span{
            border-radius: 50%;
            border: 1px solid #3E1FA5;
            position: absolute;
            line-height: 28px;
            font-weight:600;
            // padding:14px 10px;
            background-color: #fff;
            width: 30px;
            height: 30px;
            top: -14px;
            left: 50%;
            transform: translateX(-50%);
          }
          img{
            width: 80px;
            height: 80px;
          }
          h5{
            text-align: left;
            line-height: 20px;
            margin-top: 18px;
            }
        }
    }
    .inner3{
      width: calc(100vw - 40px) !important;
      margin: 0 auto  30px !important;
      h3{
        font-size: 18px;
        padding: 0 20px;
      }
      img{
        width: 90%;
        margin: 0 auto;
      }
      .inner3_item{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .content{
          margin: 20px 0;
        }
      }
    }
    .inner4{
      height: 600px;
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 200px;
          height: 220px;
        }
      }
      .right_item{
        width: calc(100vw - 40px) !important;
        margin: 0 auto !important;
        position: relative;
        .item_tips{
          width: 100%;
        }
        img{
          position: absolute;
          width: 100px;
          height: 140px;
          right: 0;
          top: -20px;
        }
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 30px 20px;
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .inner5-bg{
        padding: 20px  !important;
        height: 100px !important;
        font-size: 12px !important;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
    }
    .inner6{
      padding: 30px 20px 30px;
      h3{
        text-align: center;
        margin-bottom: 30px;
      }
      .inner6_item{
        width: 100%;
        flex-direction: column;
        .inner6_item_left{
          width: calc(100% - 40px) !important;
          padding: 20px 0;
          .inner6_item_left_title{
            font-size: 20px !important;
            text-align: center;
          }
          .inner6_item_left_list{
            justify-content: left;
            img{
              width: 17px;
              height: 17px;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .inner6_item_right{
          width: calc(100% - 80px) !important;
          margin: -20px auto 0;
          z-index: 2;
        }
      }
    }
  }
}
</style>
